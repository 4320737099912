import { useEffect } from 'react';

import config from 'config/config';

import { claimsKeys, getUser } from '@/util/auth/auth';

const useGainsightTag = () => {
	useEffect(() => {
		const scriptId = 'gainsight-px-tag';

		if (!config.gainsight_product_key || document.getElementById(scriptId)) {
			return;
		}

		const user = getUser();
		const orgRoles = user[claimsKeys.ROLES].filter((role) => role.includes('Organization')).map((orgRole) => orgRole.split('\\')[1]);
		const userFields = {
			id: user.email,
			email: user.email,
		};
		const accountFields = { id: user[claimsKeys.ORG_ACCOUNT_ID] };
		const globalContext = {
			OrganizationID: user[claimsKeys.ORG_ID],
			'Organization DisplayName': user[claimsKeys.ORG_DISPLAY_NAME],
			'Organization Role': orgRoles.join(', '),
			'Organization Type': user[claimsKeys.ORG_TYPE] ?? '',
			'App Hostname': window.location.hostname,
		};
		const onLoad = `() => {
			aptrinsic("identify",uf,af);
			aptrinsic("set","globalContext",gc);
		}`;
		const tagCode = `
			(function(n,t,a,e,co,uf,af,gc){var i="aptrinsic";n[i]=n[i]||function(){
				(n[i].q=n[i].q||[]).push(arguments)},n[i].p=e;n[i].c=co;
				var r=t.createElement("script");r.async=!0,r.src=a+"?a="+e,r.onload=${onLoad};
				var c=t.getElementsByTagName("script")[0];c.parentNode.insertBefore(r,c)
			})(window,document,"https://web-sdk-eu.aptrinsic.com/api/aptrinsic.js","${config.gainsight_product_key}",undefined,${JSON.stringify(userFields)},${JSON.stringify(accountFields)},${JSON.stringify(globalContext)});
		`;
		const script = document.createElement('script');

		script.id = scriptId;
		script.innerHTML = tagCode;

		document.body.appendChild(script);
	}, []);
};

export default useGainsightTag;
