import axios from 'axios';
import config from 'config/config';
import { getEntityId } from 'shared/src/utils/shared.js';

import { getRegionCode } from '@/util/auth/auth.js';
import { generateUrlWithPathParams, makeApiGatewayCall } from '@/util/helper';

import { WebhookOrderByTypes } from '@/components/Table/strings';

export const getEntities = ({
	name = '',
	pagination = 1,
	type,
	status = [],
	tags,
	subType,
	orderBy,
	pageSize = 10,
	includeArchived = false,
}) => {
	const data = {
		OrderBy: orderBy,
		Page: parseInt(pagination, 10),
		PageSize: pageSize,
		WhereEntityStatuses: status.length ? status.map((item) => parseInt(item, 10)) : null,
		WhereEntitySubtypes: subType ? [subType] : null,
		WhereEntityTags: tags,
		WhereEntityTypes: type ? [type] : null,
		WhereSearchText: name !== '' ? name : null,
		IncludeAnalytics: true,
		IncludeArchived: includeArchived,
	};

	const url = `${config.entities_service.base_v2}${config.entities_service.getAllWithFilters}`;

	return makeApiGatewayCall(url, 'post', data);
};

export const copyToEnvs = (entityIds, tenantIds) => {
	const url = `${config.entities_service.base}${config.entities_service.copyToEnvs}`;
	const data = {
		entityIds,
		tenantIds,
	};

	return makeApiGatewayCall(url, 'post', data);
};

export const duplicateEntity = (entityId) => {
	let url = generateUrlWithPathParams(config.entities_service.base, config.entities_service.duplicateEntity, {
		entityId,
	});

	return makeApiGatewayCall(url, 'post');
};

export const publishEntity = (entityId) => {
	const data = {
		EntityId: entityId,
	};

	let url = generateUrlWithPathParams(config.entities_service.base, config.entities_service.publishEntity, {
		entityId,
	});

	return makeApiGatewayCall(url, 'post', data);
};

export const publishManyEntities = (entityIds) => {
	const data = {
		EntityIds: entityIds,
	};

	return makeApiGatewayCall(`${config.entities_service.base}${config.entities_service.publishManyEntities}`, 'post', data);
};

export const unpublishEntity = (entityId) => {
	const data = {
		EntityId: entityId,
	};

	let url = generateUrlWithPathParams(config.entities_service.base, config.entities_service.unpublishEntity, {
		entityId,
	});

	return makeApiGatewayCall(url, 'post', data);
};

export const unpublishManyEntities = (entityIds) => {
	const data = {
		EntityIds: entityIds,
	};

	return makeApiGatewayCall(`${config.entities_service.base}${config.entities_service.unpublishManyEntities}`, 'post', data);
};

export const deleteEntity = (entityId) => {
	let url = config.entities_service.base + config.entities_service.deleteEntities;

	const data = {
		EntityIds: [entityId],
	};

	return makeApiGatewayCall(url, 'delete', {}, {}, { data });
};

export const deleteManyEntities = (entityIds) => {
	let url = config.entities_service.base + config.entities_service.deleteEntities;

	const data = {
		EntityIds: entityIds,
	};

	return makeApiGatewayCall(url, 'delete', {}, {}, { data });
};

export const setTags = ({ entityId, tags }) => {
	const data = {
		Tags: tags,
	};

	let url = generateUrlWithPathParams(config.entities_service.base, config.entities_service.setTags, {
		entityId,
	});

	return makeApiGatewayCall(url, 'post', data);
};

export const setManyTags = (newTags) => {
	const data = {
		Context: newTags.map((entityTag) => {
			return {
				EntityId: entityTag.entityId,
				Tags: entityTag.tags,
			};
		}),
	};

	return makeApiGatewayCall(`${config.entities_service.base}${config.entities_service.setManyTags}`, 'post', data);
};

export const getEntity = (entityId) => {
	let url = generateUrlWithPathParams(config.entities_service.base, config.entities_service.getEntity, {
		entityId,
	});

	return makeApiGatewayCall(url, 'get');
};

export const createEntity = ({ name, subType, type }) => {
	const data = {
		Name: name,
		Subtype: subType,
		// Tags: tags,
		Type: type,
	};

	return makeApiGatewayCall(`${config.entities_service.base}${config.entities_service.createEntity}`, 'post', data);
};

export const updateEntity = ({ entity, entityId }) => {
	const data = {
		Name: entity.name,
	};

	let url = generateUrlWithPathParams(config.entities_service.base, config.entities_service.updateEntity, {
		entityId,
	});

	return makeApiGatewayCall(url, 'put', data);
};

export const updateSites = (entityId, sites) => {
	const url = generateUrlWithPathParams(config.entities_service.base, config.entities_service.updateSites, {
		entityId,
	});
	const data = {
		SiteList: sites,
	};

	return makeApiGatewayCall(url, 'put', data);
};

export const saveBlueprint = (content, contentWidth) => {
	let data = {
		Blueprint: {
			JsonContent: JSON.stringify(content.json),
			ContentWidth: contentWidth,
		},
		EntityId: getEntityId(config),
	};

	let url = generateUrlWithPathParams(config.entities_service.base, config.entities_service.saveBlueprint);

	return makeApiGatewayCall(url, 'post', data);
};

export const updateBlueprint = (content, contentWidth, entityId = getEntityId(config)) => {
	let data = {
		Blueprint: {
			JsonContent: JSON.stringify(content.json),
			ContentWidth: contentWidth,
		},
		EntityId: entityId,
	};
	let url = generateUrlWithPathParams(config.entities_service.base, config.entities_service.updateBlueprint, {
		entityId,
	});

	return makeApiGatewayCall(url, 'put', data);
};

export const getAllBlueprints = (entityIdArg) => {
	const entityId = entityIdArg ? entityIdArg : getEntityId(config);
	if (entityId) {
		let url = generateUrlWithPathParams(config.entities_service.base, config.entities_service.getAllBlueprints, {
			entityId,
		});

		return makeApiGatewayCall(url, 'get');
	}

	return false;
};

export const transform = async (content, logicErrors = [], IsPreview = false, entityId) => {
	let data = {
		EntityId: entityId ?? getEntityId(config),
		IsPreview,
		LogicsErrors: logicErrors,
		JsonContent: content,
	};

	// TODO: Uncomment for transformer development
	// return new Promise((resolve) => {
	// 	setTimeout(() => {
	// 		const jsonParsed = JSON.parse(data.JsonContent);
	// 		const finalContent = jsonParsed.newsletter ? jsonParsed.newsletter : jsonParsed.content;
	// 		resolve(transformer(finalContent, data.EntityId, data.IsPreview, data.LogicsErrors, window));
	// 	});
	// });

	let url = generateUrlWithPathParams(config.publisher.baseUrl(getRegionCode()), config.publisher.preview(entityId ?? getEntityId(config)));

	const res = await makeApiGatewayCall(url, 'post', data);

	return res.data;
};

export const getTags = (type) => {
	let url = generateUrlWithPathParams(config.entities_service.base, config.entities_service.getTags, {
		type,
	});

	return makeApiGatewayCall(url, 'get');
};

export const getWebhooks = (page, orderBy = WebhookOrderByTypes[0], pageSize) => {
	const url = `${config.entities_service.base}${config.entities_service.getWebhooks(page, orderBy, pageSize)}`;

	return makeApiGatewayCall(url, 'get');
};

export const createWebhook = (args) => {
	let url = config.entities_service.base + config.entities_service.createWebhook;

	const data = { Settings: args };

	return makeApiGatewayCall(url, 'post', data);
};

export const updateWebhook = (webhookId, args) => {
	let url = generateUrlWithPathParams(config.entities_service.base, config.entities_service.updateWebhook, {
		webhookId,
	});

	const data = { Settings: args };

	return makeApiGatewayCall(url, 'put', data);
};

export const getWebhook = (webhookId) => {
	let url = generateUrlWithPathParams(config.entities_service.base, config.entities_service.getWebhook, {
		webhookId,
	});

	return makeApiGatewayCall(url, 'get');
};

export const searchWebhooks = (filters, orderBy = WebhookOrderByTypes[0], page, pageSize) => {
	const data = {
		Name: filters.searchTerm || null,
		OrderBy: orderBy,
		Page: page,
		PageSize: pageSize,
		FilterByAuthType: parseInt(filters.authType) || 0,
		IsInUse: ['true', 'false'].includes(filters.inUse) ? filters.inUse === 'true' : null,
	};

	const url = `${config.entities_service.base}${config.entities_service.searchWebooks}`;

	return makeApiGatewayCall(url, 'post', data);
};

export const assignWebhook = (webhookId, entityId) => {
	let url = generateUrlWithPathParams(config.entities_service.base, config.entities_service.assignWebhook, {
		entityId,
		webhookId,
	});

	return makeApiGatewayCall(url, 'post');
};

export const reassignWebhook = (webhookId, entityId) => {
	let url = generateUrlWithPathParams(config.entities_service.base, config.entities_service.reassignWebhook, {
		entityId,
		webhookId,
	});

	return makeApiGatewayCall(url, 'put');
};

export const unassignWebhook = (entityId) => {
	let url = generateUrlWithPathParams(config.entities_service.base, config.entities_service.unassignWebhook, {
		entityId,
	});

	return makeApiGatewayCall(url, 'delete');
};

export const deleteWebhook = (webhookId) => {
	let url = generateUrlWithPathParams(config.entities_service.base, config.entities_service.deleteWebhook, {
		webhookId,
	});

	return makeApiGatewayCall(url, 'delete');
};
export const testWebhook = (webhookId, payload, formId, formName) => {
	let url = generateUrlWithPathParams(config.entities_service.base, config.entities_service.testWebhook, {
		webhookId,
	});

	const data = {
		Data: payload,
	};

	return makeApiGatewayCall(url + `?FormName=${formName}&FormId=${formId}`, 'post', data);
};

export const getGoogleFonts = () => {
	return axios.get(config.google_fonts_api + config.google_fonts_key);
};

export const canAccessEntities = () => {
	return makeApiGatewayCall(`${config.entities_service.base}${config.entities_service.entitiesAccess}`, 'get');
};

export const archiveEntities = (entityIds) => {
	let url = config.entities_service.base + config.entities_service.archiveEntities;

	const data = {
		entityIds,
	};

	return makeApiGatewayCall(url, 'post', data, {});
};

export const unarchiveEntities = (entityIds) => {
	let url = config.entities_service.base + config.entities_service.unarchiveEntities;

	const data = {
		entityIds,
	};

	return makeApiGatewayCall(url, 'post', data, {});
};
