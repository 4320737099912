import { createAuth0Client } from '@auth0/auth0-spa-js';
import config from 'config/config';
import qs from 'query-string';

import { Router } from '@/routes';
import { generateUrlWithPathParams, makeApiGatewayCall } from '@/util/helper';
import WebStorage from '@/util/webStorage';

import { RegionsMap } from './regionsMap';

export const claimsKeys = {
	TENANT_ID: 'https://auth.sitecorecloud.io/claims/tenant_id',
	TENANT_NAME: 'https://auth.sitecorecloud.io/claims/tenant_name',
	ORG_ID: 'https://auth.sitecorecloud.io/claims/org_id',
	ORG_ACCOUNT_ID: 'https://auth.sitecorecloud.io/claims/org_account_id',
	ORG_DISPLAY_NAME: 'https://auth.sitecorecloud.io/claims/org_display_name',
	ORG_TYPE: 'https://auth.sitecorecloud.io/claims/org_type',
	ROLES: 'https://auth.sitecorecloud.io/claims/roles',
};

let auth0Client = null;
let regionCode = null;
let userData = null;
export const createClient = async () => {
	const query = qs.parse(location.search);
	const tenantInfoFromLS = WebStorage.get('tenant_info');
	let tenantInfo = {};

	if (query.organization && query.tenantName) {
		tenantInfo = { organization: query.organization, tenantName: query.tenantName };
		if (query.systemId) {
			tenantInfo.systemId = query.systemId;
		}
		WebStorage.set('tenant_info', tenantInfo);
		window.history.replaceState({}, document.title, location.pathname + location.search);
	} else if (tenantInfoFromLS) {
		tenantInfo = tenantInfoFromLS;
	}

	const auth0 = await createAuth0Client({
		domain: config.authentication.domain,
		clientId: config.authentication.client_id,

		authorizationParams: {
			organization_id: tenantInfo.organization,
			tenant_name: tenantInfo.tenantName,
			system_id: tenantInfo.systemId || config.authentication.xmc_system_id, // default to xmc systemId since it is not provided in the url in xmc tools page
			audience: config.authentication.audience,
			redirect_uri: window.location.origin,
		},
		useRefreshTokens: true,
		useRefreshTokensFallback: true,
	});

	auth0Client = auth0;
	return auth0;
};

export const getAuthClient = () => auth0Client;
export const getUser = () => userData;

export const getTenant = async (tenantId) => {
	let url = generateUrlWithPathParams(config.inventory.base, config.inventory.get_tenant, {
		tenantId,
	});

	return makeApiGatewayCall(url, 'get');
};

export const getRegionFromTenant = (tenantData) => {
	const regionLabel = tenantData.labels.Region; // deprecated
	const regionCodeLabel = tenantData.labels.RegionCode;

	if (!regionLabel && !regionCodeLabel) {
		throw new Error('Missing Region label on tenant');
	}

	// check for regionCode first
	if (regionCodeLabel) {
		return regionCodeLabel;
		// else use legacy region to resolve regionCode
	} else {
		const regionCode = RegionsMap[regionLabel];

		if (!regionCode) {
			throw new Error('Missing region code mapping for the label');
		}

		return regionCode;
	}
};
export const setRegionCode = (tenantData) => {
	regionCode = getRegionFromTenant(tenantData);
	return regionCode;
};
export const getRegionCode = () => regionCode;

export const handleRedirectCb = async (auth) => {
	const query = window.location.search;

	if (query.includes('code=') && query.includes('state=')) {
		// Process the login state
		const redir = await auth.handleRedirectCallback();

		//this is mostly when a user is switching orgs, to save the new auth
		await auth.getTokenSilently();
		const user = await auth.getUser();

		const tenantInfoFromLS = WebStorage.get('tenant_info');

		const sysId = tenantInfoFromLS?.systemId;

		const tenantInfo = { organization: user[claimsKeys.ORG_ID], tenantName: user[claimsKeys.TENANT_NAME] };

		if (sysId) {
			tenantInfo.systemId = sysId;
		}

		WebStorage.set('tenant_info', tenantInfo);

		Router.navigate(redir.appState.returnTo || location.pathname);

		return redir;
	}
};

export const setUserData = async (auth) => {
	await auth.getTokenSilently();
	userData = await auth.getUser();
	return userData;
};
export const checkAuthentication = async (auth) => {
	let isAuthenticated = await auth.isAuthenticated();

	if (!isAuthenticated) {
		await auth.loginWithRedirect({
			appState: { returnTo: window.location.pathname + location.search },
			authorizationParams: {
				redirect_uri: window.location.origin,
			},
		});
		return;
	}

	return isAuthenticated;
};

export const resolveAndSetRegionCode = async (auth) => {
	await auth.getTokenSilently(); // in order to place the token in memory before getting user data
	const userData = await auth.getUser();
	const tenantId = userData[claimsKeys.TENANT_ID];
	const tenant = await getTenant(tenantId);
	const region = setRegionCode(tenant.data);

	return region;
};
