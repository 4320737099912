import React from 'react';

import { DropTarget } from 'react-dnd';
import { Flipped } from 'react-flip-toolkit';
import { shallow } from 'zustand/shallow';

import useContentStore from '@/stores/ContentStore';
import useContentViewStore from '@/stores/ContentViewStore';
import useLeftSidebarStore from '@/stores/LeftSidebarStore';

import { findRow, simpleObjectEquals } from '@/util/helper';
import itemTypes from '@/util/itemTypes';
import { PagePlaceholderProperties, RowTypes, contentViewMenuItemConstants as ViewTypes } from '@/util/resources';

import PagePlaceholder from '@/components/gui/content/PagePlaceholder';
import Row from '@/components/gui/content/Row.react';
import { baseFlippedProps } from '@/components/Table/helpers';

import { DesignerEditContainer } from './EditContainers.react';

// import {FullPageEditContainer, RowFormEditContainer, StickyEditContainer, InlineEditContainer, LandingPageEditContainer, ContentEditContainer} from './EditContainers.react';

let containerSpec = {
	drop() {},
};

const ComponentByType = {
	[RowTypes.FULL]: Row,
	[RowTypes.FOUR_ONE_FOURTHS]: Row,
	[RowTypes.HALF]: Row,
	[RowTypes.THREE_ONE_THIRDS]: Row,
	[RowTypes.TWO_THIRDS_LEFT]: Row,
	[RowTypes.TWO_THIRDS_RIGHT]: Row,
	[PagePlaceholderProperties.type]: PagePlaceholder,
};

function collect(connect, monitor) {
	return {
		connectDropTarget: connect.dropTarget(),
		isOver: monitor.isOver(),
		rowBeingDragged: monitor.getItem(),
	};
}

class ContentContainer extends React.PureComponent {
	findRowAdapter = (index) => {
		return findRow(this.props.content.rows, index);
	};

	getEditingState = (isMobileView) => {
		if (!isMobileView) {
			return this.props.content;
		}

		return {
			...this.props.content,
			...this.props.content.mobileProps,
			rows: [...this.props.content.rows].sort((a, b) => a.mobileProps.index - b.mobileProps.index),
		};
	};

	render() {
		const { rowBeingDragged, isDraggingRow } = this.props;

		const isMobileView = this.props.currentView === ViewTypes.MOBILE_VIEW;

		const editingState = this.getEditingState(isMobileView);

		const groupedRows = {};

		editingState.rows.forEach((item, i) => {
			let isCurrentlyBeingDraggedRow = (rowBeingDragged && rowBeingDragged.id === item.id) || false;
			let invisible = isDraggingRow && editingState.rows.length === 1;

			let { clicked_row, clicked_element } = this.props.helper;

			if (!clicked_row) clicked_row = {};
			if (!clicked_element) clicked_element = {};

			let activeElement = (simpleObjectEquals(clicked_element.rowId, item.id) && clicked_element) || null;
			let active = (simpleObjectEquals(clicked_row, item.id) && !activeElement && clicked_row) || null;

			const grid = this.props.currentView !== ViewTypes.MOBILE_VIEW ? this.props.helper.grid : 'DISABLED';

			const lastRow = editingState.rows.length - 1 === i;

			const Component = ComponentByType[item.type];

			const rowComp = (
				<Flipped {...baseFlippedProps} flipId={item.id} key={`${item.id}-flipped`}>
					<div>
						<Component
							isOver={this.props.isOver} //this is to check if row is being dragged over the content area
							id={item.id}
							pageIndex={item.pageIndex}
							index={isMobileView ? item.mobileProps.index : i}
							lastPage={this.props.content.lastPage}
							key={`${item.id}-comp`}
							findRow={this.findRowAdapter}
							isInvisible={invisible}
							touch_screen={this.props.helper.touch_screen}
							clicked_row={this.props.helper.clicked_row}
							grid={grid}
							structureWidth={editingState.structureWidth}
							active={active}
							contentBorderWidth={editingState.borderWidth}
							isCurrentlyBeingDraggedRow={isCurrentlyBeingDraggedRow}
							lastRow={lastRow}
						/>
					</div>
				</Flipped>
			);

			if (typeof item.pageIndex !== 'undefined') {
				if (groupedRows[item.pageIndex]) {
					groupedRows[item.pageIndex].push({ component: rowComp, row: item });
				} else {
					groupedRows[item.pageIndex] = [{ component: rowComp, row: item }];
				}
			}
		});

		return (
			<DesignerEditContainer
				addStructure={this.addStructure}
				editingState={editingState}
				rows={groupedRows}
				hasRows={editingState.rows.length ? true : false}
				isMobileView={isMobileView}
				connectDropTarget={this.props.connectDropTarget}
				currentView={this.props.currentView}
				helper={this.props.helper}
				isDragging={this.props.isDragging}
				isDraggingRow={this.props.isDraggingRow}
				isOver={this.props.isOver}
				scale={this.props.scale}
				style={{
					borderRadius: editingState.structureBorderRadius,
				}}
				backgroundImage={this.props.backgroundImage}
				backgroundRepeat={this.props.backgroundRepeat}
				backgroundSize={this.props.backgroundSize}
				bg_color={this.props.bg_color}
				customRows={this.props.customRows}
				lastPage={this.props.content.lastPage}
				lastPageAll={this.props.content.lastPageAll}
				errors={this.props.validity.errors}
				summaryPage={this.props.content.summaryPage}
			/>
		);
	}

	addStructure = () => {
		useContentStore.getState().activateAddStructureBoxHelper({ rowId: null, first: true });
	};
}

const Comp = DropTarget(itemTypes.STRUCTURE, containerSpec, collect)(ContentContainer);

const ContentContainerWrapper = (props) => {
	const scale = useLeftSidebarStore((state) => state.scale);
	const currentView = useContentViewStore((state) => state.currentView);
	const contentStore = useContentStore((state) => {
		return state;
	}, shallow);

	return <Comp scale={scale} currentView={currentView} {...contentStore} {...props} />;
};

export default ContentContainerWrapper;
