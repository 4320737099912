import React from 'react';

import { Box, Text } from '@chakra-ui/react';
import { mdiCheck, mdiDotsHorizontal, mdiDotsVertical } from '@mdi/js';
import { ModalMessages } from 'config/messages';
import { AllComponentTypes } from 'shared/src/utils/shared.js';

import useContentStore from '@/stores/ContentStore';
import useModalStore from '@/stores/ModalStore';
import useTextEditorStore from '@/stores/TextEditorStore';

import { compareFontLabels, getEditorType } from '@/util/helper';
import { FontFamilyOptions, FormWidthLimit, Modals, ModalTypes } from '@/util/resources';

import GoogleFontSettings from '@/components/gui/layout/modals/GoogleFontSettings.react';
import { SettingsBox } from '@/components/gui/settingsContext/SettingsContext';
import ButtonSetting from '@/components/gui/shared/settings/ButtonSetting.react';
import CheckBoxSetting from '@/components/gui/shared/settings/CheckBoxSetting.react';
import ColorPickerSetting from '@/components/gui/shared/settings/ColorPickerSetting.react';
import DropDownSetting from '@/components/gui/shared/settings/DropDownSetting.react';
import NumberSetting from '@/components/gui/shared/settings/NumberSetting.react';
import { settingsLabelStyle } from '@/components/gui/shared/settings/Settings.styles';
import TextInputSetting from '@/components/gui/shared/settings/TextInputSetting.react';
import ToggleBlock from '@/components/gui/shared/settings/ToggleBlock.react';

import { ButtonSettingComponents } from './Button-settings.react';
import { ImageSettingComponents } from './Image-settings.react';
import { SlotSettings } from './Row-settings.react';
import SharedImageSettings from './SharedImageSettings.react';
//Element settings components
import { SharedSettingComponents } from './SharedSettings.react';
import { SocialFollowSettingComponents } from './SocialFollow-settings.react';
import { SpacerSettingComponents } from './Spacer-settings.react';
import { TextSettings } from './Text-settings.react';

export const GeneralStylesFields = {
	color: 'color',
	title: 'title',
	structureWidth: 'structureWidth',
	structureBorderRadius: 'structureBorderRadius',
	font_size: 'font_size',
	font_family: 'font_family',
	responsive: 'responsive',
	text_line_height: 'text_line_height',
	link_font_size: 'link_font_size',
	link_font_family: 'link_font_family',
	link_color: 'link_color',
	link_font_style: 'link_font_style',
	link_font_weight: 'link_font_weight',
	borderColor: 'borderColor',
	borderWidth: 'borderWidth',
	link_text_decoration: 'link_text_decoration',
	retina_images: 'retina_images',
	validation_message_required: 'validation_message_required',
	validation_message_email: 'validation_message_email',
	validation_message_phone: 'validation_message_phone',
	validation_message_recaptcha: 'validation_message_recaptcha',
	validation_message_upload_file_type: 'validation_message_uploadFileType',
	validation_message_upload_file_size: 'validation_message_uploadFileSize',
	validation_message_upload_file_count: 'validation_message_uploadFileCount',
	validation_message_upload_file_generic_error: 'validation_message_uploadFileGenericError',
};

export const GeneralComponentSettings = [
	//Text component is not included here because it has some specific extra general settings
	{
		elementType: AllComponentTypes.spacer,
		elementLabel: 'Spacer',
		ui: SpacerSettingComponents,
		extraProps: {},
	},
	{
		elementType: AllComponentTypes.image,
		elementLabel: 'Image',
		ui: ImageSettingComponents,
		extraProps: {
			fileUploadSetting: false,
			originalSizeSetting: false,
			generalSettings: true,
			recropBtn: false,
		},
	},

	{
		elementType: AllComponentTypes.submit_button,
		elementLabel: 'Button',
		ui: ButtonSettingComponents,
		extraProps: {
			decorations: false,
		},
	},
	{
		elementType: AllComponentTypes.social_follow,
		elementLabel: 'Social media',
		ui: SocialFollowSettingComponents,
		extraProps: {},
	},
];

class GeneralSettings extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			openPromo: false,
		};
	}

	componentDidMount() {
		if (this.props.leftSidebarOptions && this.props.leftSidebarOptions.scrollTop === 670) {
			this.setState({ openPromo: true });
		}
	}

	render() {
		const { isMobileView } = this.props;

		const linkProps = this.props.general_settings.link_settings;

		const fontOptions = FontFamilyOptions.concat(useTextEditorStore.getState().custom_fonts)
			.filter((item) => {
				return !item.value.includes(':');
			})
			.sort(compareFontLabels);

		let elementsSettings = GeneralComponentSettings.map((settings, key) => {
			const element = (
				<ElementSettingGroup
					key={key}
					label={settings.elementLabel}
					filterImmunity
					elementType={settings.elementType}
					onElementSettingsChange={this.onElementSettingsChange}
					onDimentionsSettingsChange={this.onDimentionsSettingsChange}
					ui={settings.ui}
					extraProps={settings.extraProps}
					settings={this.props.general_settings.components[settings.elementType]}
					dynamicComponentSetting={true}
				/>
			);

			if (settings.elementType in AllComponentTypes) {
				return element;
			}
		});

		return (
			<SettingsBox>
				<NumberSetting
					type={GeneralStylesFields.structureWidth}
					onChange={this.onStyleSettingsChange}
					text={this.props.structureWidth}
					label="Content width"
					min={0}
					max={FormWidthLimit[getEditorType()] || undefined}
					validateOnBlur
					ariaLabel="Set structure width"
				/>
				<NumberSetting
					type={GeneralStylesFields.borderWidth}
					onChange={this.onStyleSettingsChange}
					text={this.props.borderWidth}
					label="Border width"
					ariaLabel="Number of pixels"
				/>
				<ColorPickerSetting
					type={GeneralStylesFields.borderColor}
					onChange={this.onStyleSettingsChange}
					selectedColor={this.props.borderColor || 'transparent'}
					label="Border color"
				/>
				<SettingsBox filterImmunity>
					<ColorPickerSetting
						key={0}
						type="background_color"
						onChange={this.onColorChange}
						selectedColor={this.props.backgroundColor || 'transparent'}
						label="Background color"
					/>
					<SharedImageSettings
						settingType="background"
						label={this.props.background_image ? 'Change background image' : 'Add background image'}
						removeLabel="Remove background image"
						{...this.props}
						fileUploadSetting={true}
						backgroundRepeatSetting={true}
						backgroundSizeSetting={true}
						isMobileView={isMobileView}
						dimensionSettings={false}
						paddingSetting={false}
					/>
				</SettingsBox>
				{!isMobileView && (
					<ToggleBlock label="Google Fonts">
						<GoogleFontSettings filterImmunity />
					</ToggleBlock>
				)}
				{!isMobileView && (
					<CheckBoxSetting
						onClick={this.onRetinaChange}
						label="Use retina-quality images"
						checked={this.props.retina_images || false}
						type={GeneralStylesFields.retina_images}
					/>
				)}
				{isMobileView && (
					<Box mt={2.5} mb={6} textAlign="left" label="display orientation">
						<Text {...settingsLabelStyle}>Display orientation</Text>

						<Box display="flex">
							<ButtonSetting
								onClick={() => this.onResponsiveChange({ value: true })}
								tooltip="Vertical"
								className={'orientation-button'}
								icon={mdiDotsVertical}
								testId="more_vert"
								customStyle={{ mr: 5 }}
							/>
							<ButtonSetting
								testId="more_horiz"
								className={'orientation-button'}
								onClick={() => this.onResponsiveChange({ value: false })}
								tooltip="Horizontal"
								icon={mdiDotsHorizontal}
							/>
						</Box>
					</Box>
				)}

				{(!isMobileView && (
					<SettingsBox filterImmunity>
						{/* <SettingsBox fontColor="fontColors.white" fontWeight="semibold" fontSize="lg" mt={25} mb={25}>
							Default Settings
						</SettingsBox> */}
						<ToggleBlock label="Link settings">
							<ColorPickerSetting
								type={GeneralStylesFields.link_color}
								onChange={this.onLinkChange}
								selectedColor={linkProps.link_color}
								label="Link color"
							/>
							<DropDownSetting
								type={GeneralStylesFields.link_font_family}
								onChange={this.onLinkChange}
								selected={linkProps.link_font_family.replace(/'/g, '')}
								label="Link font family"
								options={fontOptions}
							/>
							<NumberSetting
								type={GeneralStylesFields.link_font_size}
								inputType="number"
								onChange={this.onLinkChange}
								text={(linkProps.link_font_size === undefined && 16) || linkProps.link_font_size}
								label="Link font size"
								ariaLabel="Font size"
							/>
							<ButtonSetting
								onClick={this.applyLinkStyles}
								tooltip="Apply to inserted links"
								label="Apply to inserted links"
								icon={mdiCheck}
							/>
						</ToggleBlock>

						<SlotSettingsGroup
							{...this.props.general_settings.slot}
							onResponsiveChange={this.onResponsiveChange}
							settings={this.props.general_settings.slot}
							responsive={this.props.general_settings.responsive || false}
							onSlotSettingsChange={this.onSlotSettingsChange}
							label="Slot settings"
							filterImmunity
						/>

						<ToggleBlock label="Text" filterImmunity>
							<ColorPickerSetting
								type={GeneralStylesFields.color}
								onChange={this.onStyleSettingsChange}
								selectedColor={this.props.color || 'transparent'}
								label="Default font color"
							/>
							<NumberSetting
								type={GeneralStylesFields.font_size}
								onChange={this.onStyleSettingsChange}
								text={this.props.font_size || 16}
								label="Default font size"
								ariaLabel="Set size"
							/>
							<TextInputSetting
								type={GeneralStylesFields.text_line_height}
								onChange={this.onStyleSettingsChange}
								text={this.props.text_line_height || ''}
								label="Default line height"
								ariaLabel="Set line height"
							/>
							<DropDownSetting
								type={GeneralStylesFields.font_family}
								onChange={this.onStyleSettingsChange}
								selected={(this.props.font_family && this.props.font_family.replace(/'/g, '')) || ''}
								label="Default font family"
								options={fontOptions}
							/>
							<SharedSettingComponents
								onChange={this.onElementSettingsChange(AllComponentTypes.text)}
								{...this.props.general_settings.components[AllComponentTypes.text]}
								filterImmunity
							/>
							<TextSettings
								{...this.props.general_settings.components[AllComponentTypes.text]}
								onChange={this.onElementSettingsChange(AllComponentTypes.text)}
								filterImmunity
							/>
						</ToggleBlock>

						<ToggleBlock label="Validation messages">
							<TextInputSetting
								type={GeneralStylesFields.validation_message_required}
								onChange={this.onValidationMessageChange}
								text={this.props.validation_messages.required || ''}
								label="Required field"
								ariaLabel="Enter custom validation message"
							/>

							<TextInputSetting
								type={GeneralStylesFields.validation_message_email}
								onChange={this.onValidationMessageChange}
								text={this.props.validation_messages.email || ''}
								label="Email field"
								ariaLabel="Enter custom validation message"
							/>

							<TextInputSetting
								type={GeneralStylesFields.validation_message_phone}
								onChange={this.onValidationMessageChange}
								text={this.props.validation_messages.phone || ''}
								label="Phone field"
								ariaLabel="Enter custom validation message"
							/>

							<TextInputSetting
								type={GeneralStylesFields.validation_message_recaptcha}
								onChange={this.onValidationMessageChange}
								text={this.props.validation_messages.recaptcha || ''}
								label="Recaptcha"
								ariaLabel="Enter custom validation message"
							/>

							<TextInputSetting
								type={GeneralStylesFields.validation_message_upload_file_type}
								onChange={this.onValidationMessageChange}
								text={this.props.validation_messages.uploadFileType || ''}
								label="File type"
								ariaLabel="Enter custom validation message"
							/>

							<TextInputSetting
								type={GeneralStylesFields.validation_message_upload_file_size}
								onChange={this.onValidationMessageChange}
								text={this.props.validation_messages.uploadFileSize || ''}
								label="File size"
								ariaLabel="Enter custom validation message"
							/>

							<TextInputSetting
								type={GeneralStylesFields.validation_message_upload_file_count}
								onChange={this.onValidationMessageChange}
								text={this.props.validation_messages.uploadFileCount || ''}
								label="File number"
								ariaLabel="Enter custom validation message"
							/>

							<TextInputSetting
								type={GeneralStylesFields.validation_message_upload_file_generic_error}
								onChange={this.onValidationMessageChange}
								text={this.props.validation_messages.uploadFileGenericError || ''}
								label="File error"
								ariaLabel="Enter custom validation message"
							/>
						</ToggleBlock>

						{elementsSettings}
					</SettingsBox>
				)) ||
					null}
			</SettingsBox>
		);
	}

	onColorChange = (colorData) => {
		let settings = {
			//background_image:'',
			bg_color: colorData.value,
		};

		//useContentStore.getState().setContentBackgroundColor(colorData.value);
		useContentStore.getState().saveContentGeneralSettings({ settings });
	};

	onStyleSettingsChange = (data) => {
		let settings = {};

		settings[data.type] = data.value;

		useContentStore.getState().saveContentGeneralSettings({ settings });
	};

	onValidationMessageChange = (data) => {
		const key = data.type.replace('validation_message_', '');
		const validationMessages = useContentStore.getState().content.validation_messages;
		const settings = {
			validation_messages: {
				...validationMessages,
				[key]: data.value,
			},
		};

		useContentStore.getState().saveContentGeneralSettings({ settings });
	};

	onDimentionsSettingsChange = (elementType) => {
		return (settings) => {
			useContentStore.getState().saveElementGeneralSettings({
				settings,
				elementType,
			});
		};
	};

	onElementSettingsChange = (elementType) => {
		return (data) => {
			let settings = {};

			if (Array.isArray(data)) {
				data.map((setting) => {
					settings = {
						...settings,
						[setting.type]: setting.value,
					};
				});
			} else {
				settings = {
					[data.type]: data.value,
				};
			}

			useContentStore.getState().saveElementGeneralSettings({
				settings,
				elementType,
			});
		};
	};

	onSlotSettingsChange = () => {
		return (data) => {
			let settings = {};

			if (Array.isArray(data)) {
				data.map((setting) => {
					settings = {
						...settings,
						[setting.type]: setting.value,
					};
				});
			} else {
				settings = {
					[data.type]: data.value,
				};
			}

			useContentStore.getState().saveSlotGeneralSettings({
				settings,
			});
		};
	};

	onResponsiveChange = (data) => {
		useContentStore.getState().setResponsive(data.value);
	};

	onRetinaChange = (data) => {
		let settings = {
			[data.type]: data.value,
		};

		useContentStore.getState().saveContentGeneralSettings({ settings });

		if (!useModalStore.getState()[ModalTypes.DONT_ASK].data.dont_ask_again.retinaImages) {
			useModalStore.getState().showDontAskModal({
				modal: Modals.INFO,
				type: 'retinaImages',
				message: ModalMessages.RETINA_WARNING,
				ignoreCancelBtn: true,
				okLabel: 'Got it',
			});
		}
	};

	onLinkChange = (data) => {
		let changeData = {
			key: data.type,
			value: data.value,
		};

		useContentStore.getState().saveLinkStyles(changeData);
	};

	applyLinkStyles = () => {
		useContentStore.getState().applyLinkStyles();
	};

	onDecorationsChange = (data) => {
		let settings = {};

		if (data.type === 'italics') {
			settings[GeneralStylesFields.link_font_style] = (data.value && 'italic') || 'normal';
		} else if (data.type === 'bold') {
			settings[GeneralStylesFields.link_font_weight] = (data.value && 'bold') || 'normal';
		} else if (data.type === 'underline') {
			settings[GeneralStylesFields.link_text_decoration] = (data.value && 'underline') || 'none';
		}

		useContentStore.getState().saveLinkDecoration(settings);
	};
}

export class ElementSettingGroup extends React.PureComponent {
	render() {
		let DynamicSettings = this.props.ui;

		return (
			<ToggleBlock label={this.props.label} filterImmunity blockId={`${this.props.label}-block-settings`}>
				<SharedSettingComponents key={0} {...this.props.settings} onChange={this.props.onElementSettingsChange(this.props.elementType)} />
				<DynamicSettings
					{...this.props.settings}
					{...this.props.extraProps}
					noUpload={true}
					onChange={this.props.onElementSettingsChange(this.props.elementType)}
					onDimensionsChange={this.props.onDimentionsSettingsChange(this.props.elementType)}
					blockId={`${this.props.label}-block-settings`}
					filterImmunity
				/>
			</ToggleBlock>
		);
	}
}

export class TextGeneralSettingsGroup extends React.PureComponent {
	render() {
		return (
			<ToggleBlock label="Text">
				<ColorPickerSetting
					type={GeneralStylesFields.color}
					onChange={this.props.onStyleSettingsChange}
					selectedColor={this.props.color || 'transparent'}
					label="Default font color"
				/>
				<NumberSetting
					type={GeneralStylesFields.font_size}
					onChange={this.props.onStyleSettingsChange}
					text={this.props.font_size || 16}
					label="Default font size"
					ariaLabel="Set size"
				/>
				<TextInputSetting
					type={GeneralStylesFields.text_line_height}
					onChange={this.props.onStyleSettingsChange}
					text={this.props.text_line_height || ''}
					label="Default line height"
					ariaLabel="Set line height"
				/>
				<DropDownSetting
					type={GeneralStylesFields.font_family}
					onChange={this.props.onStyleSettingsChange}
					selected={(this.props.font_family && this.props.font_family.replace(/'/g, '')) || ''}
					label="Default font family"
					options={this.props.allFontOptions}
				/>
				<SharedSettingComponents {...this.props.settings} onChange={this.props.onElementSettingsChange(AllComponentTypes.text)} />
				<TextSettings {...this.props.settings} onChange={this.props.onElementSettingsChange(AllComponentTypes.text)} />
			</ToggleBlock>
		);
	}
}

export const SlotSettingsGroup = React.memo((props) => {
	return (
		<SlotSettings
			label="Slot"
			onChange={props.onSlotSettingsChange}
			types={{
				padding: 'padding',
				background_color: 'background_color',
				vertical_align: 'vertical_align',
				borderWidth: 'borderWidth',
				borderColor: 'borderColor',
				borderRadius: 'borderRadius',
				minHeight: 'minHeight',
			}}
			background_color={props.background_color}
			vertical_align={props.vertical_align}
			borderWidth={props.borderWidth}
			borderRadius={props.borderRadius}
			multiPadding={props.multiPadding}
			borderColor={props.borderColor}
			minHeight={props.minHeight}
			contentBgColor={props.contentBgColor}
			padding={props.padding}
			filterImmunity
		/>
	);
});

export default GeneralSettings;
